import React from "react"

import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import { NAVIGATION_ELEMENT } from "constants/ContentfulContentTypes"
import CTAArrow from "assets/svg/cta-arrow"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import ServerMessage from "components/ServerMessage/ServerMessage"
import CTA from "components/CTA/CTA"

import "../styles/main.scss"
import styles from "../styles/pages/404.module.scss"

const NotFound = ({ data }) => {
  const header = data.page?.content?.find(
    (item) => item.title === "404 Headline"
  )

  const message = data.page?.content?.find(
    (item) => item.title === "404 Details"
  )

  const cta = data.page?.content?.find(
    (item) => item.__typename === NAVIGATION_ELEMENT
  )

  if(typeof window !== 'undefined'){
    if(window.location.pathname !== window.location.pathname.toLowerCase() ){
      window.location.href = window.location.href.toLowerCase();
    }
  }

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout>
        <SEO title={data.page.title} />
        <main className={styles.content}>
          <PageTitle title={data.page.title} />
          <ServerMessage title={header.content} message={message.content}>
            <CTA
              clickHandler={() => (window.location.href = '/')}
              classes={styles.cta}
            >
              {cta.label}
              <CTAArrow classes={styles.icon} />
            </CTA>
          </ServerMessage>
        </main>
      </Layout>
    </StateProvider>
  )
}

export default NotFound

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "404" }) {
      label
      url
      title
      content {
        ...TopicFragment
        ...NavigationElementFragment
      }
    }
  }
`
